
  import { defineComponent, onMounted, ref } from 'vue';
  import { ErrorMessage, Field, Form } from 'vee-validate';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { useRouter, useRoute } from 'vue-router';
  import { Actions } from '@/store/enums/StoreEnums';

  import { useAbility } from '@casl/vue';
  import Swal from 'sweetalert2/dist/sweetalert2.js';
  import * as Yup from 'yup';
  import { IreCommonAreaObject } from '@/store/modules/IRE/IreCommonAreasModule';

  export default defineComponent({
    name: 'ire-industrial-areas-editing',
    components: {
      ErrorMessage,
      Field,
      Form,
    },
    data: () => ({
      selectedDataConfig: '',
    }),
    methods: {
      sendInfo(item, type) {
        this.selectedDataConfig = item;
        this.selectedDataConfig['type'] = type;
      },
    },
    async setup() {
      const { t, te } = useI18n();
      const store = useStore();
      const router = useRouter();
      const route = useRoute();
      const { can } = useAbility();
      const submitButton = ref<HTMLButtonElement | null>(null);

      onMounted(() => {
        setCurrentPageBreadcrumbs(translate('areasIreValuesUpdating'), [
          translate('ire'),
        ]);
      });

      const { data } = await store.dispatch(
        Actions.GET_IRE_COMMON_AREA,
        route.params.id
      );

      const area = ref<IreCommonAreaObject>(data.data);
      if (area.value.customUpdatedAt) {
        const dateString = new Date(area.value.customUpdatedAt);

        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');

        area.value.customUpdatedAt = `${year}-${month}-${day}`;
      } else {
        area.value.customUpdatedAt = '';
      }

      const translate = (text: string) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      const goBack = () => {
        router.back();
      };

      const areaSchema = Yup.object().shape({
        sort: Yup.number()
          .required(() => translate('SORT_IS_REQUIRED_FIELD'))
          .label('sort'),
      });
      const onSubmitUpdate = async (values) => {
        delete values.AreasName;

        if (submitButton.value) {
          // eslint-disable-next-line
          submitButton.value!.disabled = true;
          submitButton.value.setAttribute('data-kt-indicator', 'on');
        }

        const payload = {
          sort: Number(values.sort),
          customUpdatedAt: values.customUpdatedAt
            ? values.customUpdatedAt
            : undefined,
        };
        await store.dispatch(Actions.UPDATE_IRE_COMMON_AREA, {
          id: area.value?.id,
          data: payload,
        });
        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];

        if (error) {
          Swal.fire({
            text: translate(error[0]),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
          //Deactivate indicator
          submitButton.value?.removeAttribute('data-kt-indicator');
          // eslint-disable-next-line
          submitButton.value!.disabled = false;
        } else {
          Swal.fire({
            text: translate('SUCCESSFULLY_UPDATED_AREA'),
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: translate('ok'),
            customClass: {
              confirmButton: 'btn btn-light-primary',
            },
          }).then(() => {
            router.go(0);
          });
        }
      };
      // await store.dispatch(Actions.GET_ALL_AREAS_LIST, {
      //    'ire-literal',
      // });

      store.dispatch(Actions.REMOVE_BODY_CLASSNAME, 'page-loading-over');

      return {
        onSubmitUpdate,
        submitButton,
        translate,
        areaSchema,
        goBack,
        area,
        can,
      };
    },
  });
